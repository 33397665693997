const brand = {
  key: 'ligne-roset',
  path: 'ligne-roset',
  accessKey: process.env.REACT_APP_BRAND_ACCESS_KEY,
  name: 'Ligne Roset',
  website: {
    url: 'https://www.ligne-roset.com/',
    domain: 'Ligne Roset.com',
  },
  faqUrl: 'https://www.ligne-roset.com/fr/faq',
  features: {
    withUidCheck: true,
    withReferenceInput: false,
    withTextRecognition: false,
    withIssueRequest: true,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1718359972/Photos/Ligne%20Roset/LOGO_LIGNE_ROSET_DEPUIS_1860_n8csfw.png',
    homePage: 'https://pub.grouperoset.net/pao/2024/TRUST_PLACE/IMG_TOGO_5_4.jpg',
    homePageCompressed: 'https://pub.grouperoset.net/pao/2024/TRUST_PLACE/IMG_TOGO_5_4.jpg',
    certificateExample: null,
    certificateExampleCompressed: null,
    favicon: null,
    uidExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1686559793/certificate-request-page/brands/giambattista%20Valli/uid-example_mavfkh.jpg',
  },
  socialLinks: {
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
  },
};

export default brand;
